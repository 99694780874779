import React from 'react';
import Leads from '../../Leads';
import './ContactForm.scss';
import { decodeEntities } from '../../../utils/helpers';

export const ContactForm = ({ title }) => {
  return (
    <section className="contact-form-block">
      <div className="wrap">
        <h2>{decodeEntities(title)}</h2>
        <Leads id="6" />
      </div>
    </section>
  )
}
