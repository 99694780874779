import React from 'react';
import OnVisible from 'react-on-visible';
import { decodeEntities } from '../../../utils/helpers';
import RenderContent from '../../RenderContent';
import { Actions } from '../../Actions';
import './IllustrationAndContent.scss';

export const IllustrationAndContent = (props) => {
  const {
    illustration,
    title,
    content,
    actions
  } = props;
  return (
    <OnVisible wrappingElement="section" percent={-10} className={`illustration-and-content ${illustration}`}>
      <div className="wrap">
        <div className="illustration-container" />
        <div className="content-container">
          <h2>{decodeEntities(title)}</h2>
          <RenderContent content={content} className="content" />
          <Actions actions={actions} />
        </div>
      </div>
    </OnVisible>
  )
}
