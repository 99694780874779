import React from 'react';
import "./Iframe.scss";

export const Iframe = ({ url }) => {
    return (
        <section className="iframe">
            <div className="wrap">
                <iframe src={url} title="iframe" className="frame" />
                <div className="loading-spinner"><div></div><div></div><div></div><div></div></div>
            </div>
        </section>
    )
}