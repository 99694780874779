import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Actions } from '../../Actions';
import { decodeEntities } from '../../../utils/helpers';
import { Plant1, Plant2, Plant3 } from '../../Svg';
import './HomepageHeader.scss';

export const HomepageHeader = (props) => {
  const {
    title,
    content,
    actions,
    includePhone
  } = props;
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          locations {
            phone
          }
        }
      }
    }
  `);
  // Check phone number exists
  const phone = siteSettings.options && siteSettings.options.locations.length > 0 && siteSettings.options.locations[0].phone ?
    siteSettings.options.locations[0].phone : null;

  return (
    <section className="homepage-header">
      <div className="wrap">
        <Plant1 className="plant1" />
        <Plant2 className="plant2" />
        <Plant3 className="plant3" />
        <div className="content-container">
          <h1 className="homepage-header-title">{decodeEntities(title)}</h1>
          <div className="homepage-header-content" dangerouslySetInnerHTML={{__html: content}} />
          <Actions actions={actions} className="homepage-header-actions" />
          {includePhone && phone && (
            <span className="phone-cta">
              Or call <a href={`tel:${phone}`}>{phone}</a>
            </span>
          )}
        </div>
      </div>
    </section>
  )
}
