import React, { Component } from 'react';
import Carousel from '../../Carousel';
import { decodeEntities } from '../../../utils/helpers';
import './LogoFeed.scss';
import RenderContent from '../../RenderContent';
import { Actions } from '../../Actions';

class ThisSlider extends Component {
  render () {
    const { gallery } = this.props;
    const settings = {
      infinite: gallery?.length > 4 ? true : false,
      dots: true,
      arrows: false,
      autoplay: true,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 700,
          settings: "unslick"
        }
      ]
    };
    return (
      <div className={`logo-carousel items-${gallery?.length}`}>
        <Carousel {...settings}>
          {gallery && gallery.map((slide, slide_count) => slide.source_url && (
            <div key={slide_count} className="slide">
              <div className="aspect">
                {slide.source_url && <img src={slide.source_url} className="logo" />}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export const LogoFeed = (props) => {
  const { logos: gallery, title, content, actions } = props;
  return (
    <section className="logo-feed">
      <div className="wrap">
        <div className="title"><h3>{decodeEntities(title)}</h3></div>
        <RenderContent className="content" content={content} />
        <div className="inner">
          <ThisSlider gallery={gallery} />
        </div>
        <Actions actions={actions} className="logo-feed-actions" />
      </div>
    </section>
  )
}
