import React from 'react';
import RenderContent from '../../RenderContent'
import GatsbyLink from '../../GatsbyLink'
import { QuickLinksIcons } from '../../../images/graphics/QuickLinks/'
import './QuickLinksBlock.scss';
import { decodeEntities } from '../../../utils/helpers';

/**
 * This component needs the icon set to map appropriate icons from its data
 */

export const QuickLinksBlock = (props) => {
  const { title = `Know what you're looking for?`, content, links } = props
  return (
    <section className="quick-links-block">
      <div className="wrap">
        <ContentBlock title={title} content={content && content} />
        <LinksBlock linkItems={links} />
      </div>
    </section>
  )
}

const LinksBlock = (props) => {
  const { linkItems } = props;
  if (!linkItems) return null;
  return (
    <div className="link-item-wrapper">
      {linkItems && linkItems.map(item => {
        return (
          <div key={item.icon} className="link-item-inner">
            <GatsbyLink to={item.link ? item.link.url : '#'} className="link-item-inner__icon">
              <img className="icon-object" src={QuickLinksIcons[item.icon]} />
            </GatsbyLink>
            <div className="link-item-inner__content">
              <h3><GatsbyLink to={item.link ? item.link.url : '#'}>{decodeEntities(item.title)}</GatsbyLink></h3>
              <p>{item.content}</p>
            </div>
            <GatsbyLink className="action link-item--cta-mobile" to={item.link ? item.link.url : ''}>Read more</GatsbyLink>
          </div>
        )
      })}
    </div>
  )
}

const ContentBlock = (props) => {
  if (!props) return null
  const { title, content } = props
  return (
    <div className="content-block">
      <div className="title-wrapper">
        <h2>{title}</h2>
      </div>
      <div className="content-wrapper">
        {content && <RenderContent content={content} />}
      </div>
    </div>
  )
}
