import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Actions } from '../../Actions';
import './KeysKidsHeader.scss';
import { decodeEntities } from '../../../utils/helpers';

export const KeysKidsHeader = (props) => {
  const {
    template,
    title,
    content,
    actions,
    includePhone
  } = props;
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          locations {
            phone
          }
        }
      }
    }
  `);
  // Check phone number exists
  const phone = siteSettings.options && siteSettings.options.locations.length > 0 && siteSettings.options.locations[0].phone ?
    siteSettings.options.locations[0].phone : null;
  return (
    <section className={`keys-kids-header ${template}`}>
      <div className="wrap">
        <div className="content-container">
          <h1 className="keys-kids-header-title">{decodeEntities(title)}</h1>
          <div className="keys-kids-header-content" dangerouslySetInnerHTML={{__html: content}} />
          <Actions actions={actions} className="keys-kids-header-actions" />
          {includePhone && phone && (
            <span className="phone-cta">
              Or call <a href={`tel:${phone}`}>{phone}</a>
            </span>
          )}
        </div>
      </div>
    </section>
  )
}
