import React, { useEffect, useState } from 'react';
import OnVisible from 'react-on-visible';
import { ImageAndContentBlock } from '../../ImageAndContentBlock'
import Slider from "react-slick";
import Carousel from '../../Carousel'
import RenderContent from '../../RenderContent'
import { useWindowSize } from '../../../utils/helpers';
import { Actions } from '../../Actions'
import './ImageAndContentTabs.scss';


export const ImageAndContentTabs = (props) => {
  const { sections, title, content } = props;
  return (
    <section className="image-and-content-tabs">
      <OnVisible wrappingElement="div" percent={-10} className="wrap">
        <TitleAndContent title={title && title} content={content && content} />
        <ImageTabSlider sections={sections && sections} />
      </OnVisible>
    </section>
  )
}

const TitleAndContent = (props) => {
  if (!props) return <p className="error">Missing props in TitleAndContent</p>
  const { title, content } = props
  return (
    <div className="title-and-content-wrapper">
      {title && 
        <div className="title-wrapper">
          <h2>{title}</h2>
        </div>
      }
      <div className="content-wrapper">
        {content && <RenderContent content={content} />}
      </div>
    </div>
  )
}
/**
 * In this slider we want to render *two* sliders on mobile:
 * This will let us achieve the affect of having "the reference appear above the below" from design
 */
const ImageTabSlider = (props) => {
  const { sections } = props
  if (!sections.length > 0) return <p className="error">Slider missing repeatable items</p> 

  const [isMobileSlider, setIsMobileSlider] = useState(false);

  const SliderSettings = {
    customPaging: function(i) {
      return (
        <div className="slider-title-index">
          <span className="slider-title__inner">{sections[i].title}</span>
        </div>
      );
    },
    arrows: false,
    adaptiveHeight: true,
    fade: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          dots: false,
          centerMode: true,
          fade: false,
          slidesToShow: 1,
        }
      }
    ],
  }

  const mobileOnlySettings = {
    centerMode: true,
    speed: 200,
    fade: false,
    slidesToShow: 1,
    centerPadding: '0px',
  }

  let windowSize = useWindowSize();
  useEffect(() => {
    function toggleSliderRender() {
      if (windowSize.width < 1080) {
        setIsMobileSlider(true)
      } else {
        setIsMobileSlider(false)
      }
    }
    toggleSliderRender();
  })

  const [navOne, setNavOne] = useState(null)
  const [navTwo, setNavTwo] = useState(null)

  if (isMobileSlider) {
    return (
      <div className="multiple-carousel">
        <Slider className="slider-title-only" ref={slider1 => setNavOne(slider1)} asNavFor={navTwo} slidesToShow={2} arrows={false} focusOnSelect>
          {sections && sections.filter(Boolean).map((item, index) => {
            return (
              <div key={item.title} className="multiple-carousel__title-wrapper">
                <span className="multiple-carousel__inner">{item.title}</span>
              </div>
            )}
          )}
        </Slider>
        <Slider {...mobileOnlySettings} ref={slider2 => setNavTwo(slider2)} asNavFor={navOne} arrows={false} adaptiveHeight>
          {sections && sections.filter(Boolean).map((item, index) => {
            return (
              <div key={item.title} className="multiple-carousel__content">
                <h3>{item.title}</h3>
                <RenderContent content={item.content} />
                {item.actions && <Actions actions={item.actions} />}
              </div>
            )}
          )}
        </Slider>
      </div>
    )
  }
  if (!isMobileSlider) {
    return (
      <Carousel {...SliderSettings}>
        {sections && sections.filter(Boolean).map((item, index) => {
          return (
            <ImageAndContentBlock key={index} props={{...item}} />
          )}
        )}
      </Carousel>
    )
  }
}