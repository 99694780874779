import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../components/Acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/helpers';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="wrap">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }

};

const Page = ({ data, location, pageContext }) => {
  const { wordpressPost: page, site, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  if (!page) return null;
  const { title, yoast, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle } = wordpressWpSettings;
  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
          />
        );
      })}
    </Layout>
  )
}

export default Previewable(Page, 'page');
//export default (Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        layout: layout_page {
          __typename
          ... HomepageHeaderQuery
          ... StandardHeaderQuery
          ... KeysKidsHeaderQuery
          ... ImageAndContentQuery
          ... ImageAndContentTabsQuery
          ... IllustrationAndContentQuery
          ... LogoFeedQuery
          ... VerticalSliderQuery
          ... TeamMembersBlockQuery
          ... QuickLinksBlockQuery
          ... TestimonialsBlockQuery
          ... FooterTopBlockQuery
          ... ConcertinaQuery
          ... LinkListBlockQuery
          ... ContactFormQuery
          ... MapBlockQuery
          ... IntroBlockQuery
          ... IframeQuery
        }
      }
    }
  }
`
