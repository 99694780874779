import React from 'react';
import Accordion from '../../Accordion';
import RenderContent from '../../RenderContent'
import './Concertina.scss';

export const Concertina = props => {
  const { content: concertinaHeadingContent, concertina, sectionContent } = props;
  return (
    <section className="concertina">
      <div className="wrap">
        <div className="inner">
          {concertinaHeadingContent && <RenderContent content={concertinaHeadingContent} />}
          {sectionContent && <RenderContent content={sectionContent} />}
          <Accordion concertina={concertina} />
        </div>
      </div>
    </section>
  );
};
