import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './MapBlock.scss';

const isClient = typeof window !== 'undefined';

export const MapBlock = (props) => {
  const { siteSettings } = useStaticQuery(graphql`
    {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          locations {
            googleMap
          }
        }
      }
    }
  `);
  return (
    <section className="map-block">
      {isClient &&
       siteSettings.options.locations &&
       siteSettings.options.locations.length > 0 &&
       siteSettings.options.locations[0].googleMap &&
       <iframe src={`${siteSettings.options.locations[0].googleMap}`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>}
    </section>
  );
}
