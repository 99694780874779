import React from 'react';
import OnVisible from 'react-on-visible';
import RenderContent from '../../RenderContent'
import './IntroBlock.scss';

export const IntroBlock = (props) => {
  const { content } = props;
  return (
    <section className="intro-block">
      <OnVisible wrappingElement="div" percent={-10} className="wrap">
        <div className="content-wrapper">
          {content && <RenderContent content={content} />}
        </div>
      </OnVisible>
    </section>
  )
}
