import more from './more.svg';
import apple from './apple.svg';
import document from './document.svg';
import appointment from './appointment.svg';
import crown from './crown.svg';
import denture from './denture.svg';
import faq from './faq.svg';
import filling from './filling.svg';
import implant from './implant.svg';
import location from './location.svg';
import form from './form.svg';
import star from './star.svg';
import time from './time.svg';
import zip from './zip.svg';

export const QuickLinksIcons = {
  'more': more,
  'apple': apple,
  'document': document,
  'appointment': appointment,
  'crown': crown,
  'denture': denture,
  'faq': faq,
  'filling': filling,
  'implant': implant,
  'location': location,
  'form': form,
  'star': star,
  'time': time,
  'zip': zip
};