import React from 'react';
import OnVisible from 'react-on-visible';
import RenderContent from '../../RenderContent';
import GatsbyLink from '../../GatsbyLink';
import { decodeEntities } from '../../../utils/helpers';
import './LinkListBlock.scss';

export const LinkListBlock = (props) => {
  const {
    title,
    content,
    links,
    backgroundColor
  } = props;
  return (
    <section className={`link-list-block ${backgroundColor}`}>
      <OnVisible wrappingElement="div" percent={-10} className="wrap">
        <div className="link-list-block-intro-container">
          <h2 className="link-list-block-title">{decodeEntities(title)}</h2>
          <RenderContent className="link-list-block-intro" content={content} />
        </div>
        <div className="link-list-block-list">
          {links && links.length > 0 && links.map(link => {
            if (!link.link) return null;
            // eslint-disable-next-line no-shadow
            const { url, title } = link.link;
            return (
              <GatsbyLink key={url} to={url} className="link-list-block-list-item" decode>
                {title}
              </GatsbyLink>
            );
          })}
        </div>
      </OnVisible>
    </section>
  )
}
