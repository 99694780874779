import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Image } from '../../Image';
import { Actions } from '../../Actions';
import { decodeEntities } from '../../../utils/helpers';
import locationSvg from '../../../images/graphics/QuickLinks/location.svg';
import timeSvg from '../../../images/graphics/QuickLinks/time.svg';
import './StandardHeader.scss';

export const StandardHeader = (props) => {
  const {
    title,
    content,
    actions,
    includePhone,
    includeContactDetails,
    noPaddingBottom,
    image
  } = props;
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          locations {
            addressDisplay
            addressCity
            addressRegion
            addressStreet
            addressPostCode
            email
            phone
            contact_hours {
              days
              hours
            }
          }
        }
      }
    }
  `);
  // Check phone number exists
  // const phone = siteSettings.options && siteSettings.options.locations.length > 0 && siteSettings.options.locations[0].phone ?
  //   siteSettings.options.locations[0].phone : null;
  const {
    email,
    phone,
    addressDisplay,
    addressCity,
    addressRegion,
    addressStreet,
    addressPostCode,
    contact_hours: openingHours
  } = siteSettings.options.locations[0];
  return (
    <section className={`standard-header${image && image.source_url !== '' ? ' has-image' : ''}${includeContactDetails ? ' include-contact' : ''}`}>
      <div className="standard-header-main-content">
        <Image src={image} />
        <div className="wrap" style={noPaddingBottom && { paddingBottom: 0 }}>
          <h1 className="standard-header-title">{decodeEntities(title)}</h1>
          <div className="standard-header-content" dangerouslySetInnerHTML={{__html: content}} />
          <Actions actions={actions} />
          {includePhone && phone && (
            <span className="phone-cta">
              Or call <a href={`tel:${phone}`}>{phone}</a>
            </span>
          )}
        </div>
      </div>
      {includeContactDetails && (
        <div className="standard-header-contact-details">
          <div className="wrap">
            <div className="location">
              <div className="standard-header-contact-details-icon">
                <img src={locationSvg} alt="Map pin icon" />
              </div>
              <h2>Location</h2>
              <span className="address-first-line">{addressStreet}</span>
              <span className="address-second-line">{addressCity} {addressRegion} {addressPostCode}</span>
              <span className="phone"><a href={`tel:${phone}`}>{decodeEntities(phone)}</a></span>
              <span className="email"><a href={`mailto:${decodeEntities(email)}`}>{decodeEntities(email)}</a></span>
            </div>
            <div className="opening-hours">
              <div className="standard-header-contact-details-icon">
                <img src={timeSvg} alt="Time icon" />
              </div>
              <h2>Opening hours</h2>
              {openingHours && (
                <div className="hours-lines">
                  {openingHours.map(time => 
                    <div key={time.days} className="line">
                      <span><strong>{time.days}</strong></span><span>{time.hours}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
