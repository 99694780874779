import React from 'react';
import OnVisible from 'react-on-visible';
import { ImageAndContentBlock } from '../../ImageAndContentBlock'
import './ImageAndContent.scss';

export const ImageAndContent = (props) => {
  const { backgroundColor = 'white' } = props
  return (
    <section className={`image-and-content ${backgroundColor && `theme-${backgroundColor}`}`}>
      <OnVisible percent={-10} wrappingElement="div" className="wrap">
        <ImageAndContentBlock props={{...props}} />
      </OnVisible>
    </section>
  )
}

