import React from 'react';
import OnVisible from 'react-on-visible';
import Carousel from '../../Carousel'
import { ImageAndContentBlock } from '../../ImageAndContentBlock'
import RenderContent from '../../RenderContent'
import './VerticalSlider.scss';
import { decodeEntities } from '../../../utils/helpers';


export const VerticalSlider = (props) => {
  const { sliderItems, title, content } = props;
  return (
    <section className="vertical-slider">
      <OnVisible wrappingElement="div" percent={-10} className="wrap">
        <h2>{decodeEntities(title)}</h2>
        <RenderContent className="vertical-slider-intro" content={content} />
        {sliderItems && <CarouselWrap props={sliderItems} />}
      </OnVisible>
    </section>
  );
}

const settings = {
  dotsClass: 'vertical-slick-dots',
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        vertical: false,
      }
    }
  ]
};

const CarouselWrap = ({props}) => {
  if (!props) return null;
  const [ ...sliderItems ] = props
  return (
    <div className="vertical-slider__carousel-wrapper">
      <Carousel {...settings}>
        {sliderItems && sliderItems.map((slide, index) => {
          return (
            <ImageAndContentBlock key={index} props={{...slide}} />
          )
        })}
      </Carousel>
    </div>
  )
}