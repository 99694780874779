import React from 'react';
import OnVisible from 'react-on-visible';
import RenderContent from '../RenderContent';
import { Image } from '../Image'
import { Actions } from '../Actions'
import './ImageAndContentBlock.scss'

export const ImageAndContentBlock = ({props}) => {
  const {
    arrangement = 'image-left'
  } = props
  return (
    <OnVisible wrappingElement="div" className={`image-content-block ${arrangement}`}>
      <ImageBlock props={{...props}} />
      <ContentBlock props={{...props}} />
    </OnVisible>
  )
}

const ImageBlock = ({props}) => {
  const { image } = props
  return (
    <div className="image-block">
      <Image src={image} />
    </div>
  )
}

const ContentBlock = ({props}) => {
  const { actions, content, title } = props
  return (
    <div className="content-block">
      <h2 className="content-block__title">{title}</h2>
      {content && <RenderContent content={content} />}
      {actions && <Actions actions={actions} />}
    </div>
  )
}