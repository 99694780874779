import React, { useState } from 'react';
import { Image } from '../../Image';
import Carousel from '../../Carousel';
import { decodeEntities } from '../../../utils/helpers';
import './TeamMembersBlock.scss';

export const TeamMembersBlock = (props) => {
  const [slick, setSlick] = useState(null);
  const { title, teamMembers } = props;
  const settings = {
    infinite: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    variableWidth: true,
    getSlick: (thisSlick) => {
      if (!slick && thisSlick) {
        setSlick(thisSlick);
      }
    },
    responsive: [
      {
        breakpoint: 700,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  };
  
  const goToSlide = (event, activeIndex) => {
    if (slick && activeIndex !== null) {
      if (activeIndex < slick.innerSlider.state.currentSlide) {
        slick.slickGoTo(slick.innerSlider.state.slideCount + activeIndex);
      }
      slick.slickGoTo(activeIndex);
    }
  }

  return (
    <section className="team-members-block">
      <div className="wrap">
        <div className="title-container">
          <h2>{decodeEntities(title)}</h2>
        </div>
        {teamMembers && teamMembers.length > 0 && (
          <div className="team-members-slider">
            <Carousel {...settings}>
              {teamMembers.map((teamMember, index) => {
                const { image: teamMemberImage, name: teamMemberName, title: teamMemberTitle, bio } = teamMember;
                return (
                  <div key={teamMemberName ? teamMemberName : teamMemberTitle} className="team-member-slide" onClick={event => goToSlide(event, index)}>
                    <div className="team-member-image-container">
                      {bio && <div className="team-member-bio" dangerouslySetInnerHTML={{__html: bio}} />}
                      <div className="team-member-image">
                        {teamMemberImage && <Image src={teamMemberImage} position="relative" />}
                      </div>
                    </div>
                    <span className="team-member-name">{decodeEntities(teamMemberName)}</span>
                    {teamMemberTitle && <span className="team-member-title">{decodeEntities(teamMemberTitle)}</span>}
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>
    </section>
  )
}
