import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import RenderContent from '../../RenderContent'
import Carousel from '../../Carousel'
import { QuoteMarker } from '../../Svg/QuoteMarker'
import './TestimonialsBlock.scss';

export const TestimonialsBlock = (props) => {
  const { title } = props;
  const testimonials = useTestimonialQuery();
  return (
    <section className="testimonials-block">
      <div className="wrap">
        <div className="title-wrapper">
          <h2>{title}</h2>
          <div className="quote-marker-wrapper">
            <span className="quote">“</span>
          </div>
        </div>
        {testimonials && <Testimonials testies={testimonials} />}
      </div>
    </section>
  )
}

const Testimonials = ({testies}) => {
  if (!testies) return <p>Missing props.</p>;
  const settings = {
    infinite: true,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          adaptiveHeight: true,
          arrows: false,
        }
      }
    ]
  };
  return (
    <Carousel {...settings}>
      {testies.filter(Boolean).map(({node: testimonial}) =>
        <div key={testimonial.content} className="testimonial-inner-wrapper">
          <RenderContent content={testimonial.content} />
          <div className="testimonial-meta">
            <p className="testimonial-meta__name"><strong>{testimonial.acf.name}</strong></p>
            <p className="testimonial-meta__position">{testimonial.acf.position}</p>
          </div>
        </div>
      )}
    </Carousel>
  )
}

/**
 * Retrieve all testimonials
 */
const useTestimonialQuery = () => {
  const { allWordpressWpTestimonials } = useStaticQuery(
    graphql`
      query WpTestimonialQuery {
        allWordpressWpTestimonials {
          edges {
            node {
              acf {
                name
                position
              }
              content
            }
          }
        }
      }
    `
  )
  return allWordpressWpTestimonials.edges
}